import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Transaction } from "./../dashboard/dashboard.model";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventService } from "src/app/core/services/event.service";
import { ApiService } from "src/app/shared/services/api.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { NgbDate, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';

interface listData {
  created_at: string;
  customer_id: number;
  device_id: number;
  id: number;
  status: number;
  total: number;
  transaction_code: string;
  updated_at: string;
}

@Component({
  selector: "app-detail-workspace",
  templateUrl: "./detail-workspace.component.html",
  styleUrls: ["./detail-workspace.component.scss"],
})
export class DetailWorkspaceComponent implements OnInit {
  params = "";
  phone: Number;
  filter = {
    // location_id: -1,
    status: -1,
    start_date: -1,
    end_date: -1,
  };
  leader = "";
  data = <any>{};
  dataMember = <any>{};
  formData: FormGroup
  data1 = <any>{};
  data2 = <any>{};
  listData = [];
  listData1 = [];
  listData2 = [];
  listDataDivices = [];
  id: number = 0;
  // eventService: any;
  pageSize = 10;
  pageIndex = 1;
  totalCount = 2;
  totalCount1 = 2;
  formData2: FormGroup;
  tableName: string = "workspaces/";
  tableName2 = "devices/active";
  listAccount = [];
  idUser = JSON.parse(localStorage.getItem("currentUser")).id;
  userToken = JSON.parse(localStorage.getItem("currentUser")).user_token;
  listBankname = [
    {
      name: "MB Bank",
      id: "970422",
    },
  ];

  storeName: string;
  phoneNumber: string;
  storeAddress: string;
  listDevicesQr
  dataUser
  formUpdateDevice: FormGroup;
  userId: string;
  terminalID: string;
  serialNumber: string;
  servicePackage: string;
  activationDate: string;



  role = JSON.parse(localStorage.getItem("currentUser")).role;
  // public person
  constructor(
    public eventService: EventService,
    private apiService: ApiService,
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params["id"];
    this.fetchData();
    this.fetchData2();
    this.fetchDataDevices();
    this.fetchDataBankAccount();
    this.fetchDataMember();

    // this.formUpdateDevice = this.formBuilder.group({
    //   user_id: [''],
    //   terminalID: [''],
    //   serial_number: [''],
    //   service_package: [''],
    //   active_date: ['']
    // });
  }

  countMember: number;
  countTransaction: number;
  listMember = [];

  fetchData() {
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableName +
        this.id +
        `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`
      )
      .subscribe({
        next: (res) => {
          this.data = res;
          this.storeName = res.workspace_name;
          this.storeAddress = res.address;
          this.accountNumber = res.account_number;
          this.bank_accountName = res.bank_account_name;
          this.listData = this.data;
          this.leader = this.data.leader;
          this.dataMember = res.members;
          this.listMember = this.dataMember;
          this.countMember = res.members.length ? res.members.length : 0;
          this.countTransaction = res.transactions.length
            ? res.transactions.length
            : 0;
          this.eventService.setLoading(false);
        },
      });
  }
  fullname = JSON.parse(localStorage.getItem("currentUser")).full_name;

  searching = false;

  binNumber = "970422";
  accountNumber = "";
  bank_accountName = "";
  deviceName
  onEdit(data, content) {
    this.listDevicesQr = data.serial_number
    this.dataUser = data
    this.formData = this.makeForm(data)
    console.log(this.formData)
    this.onDeviceChange(this.listDevicesQr[0])
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }
  onDeviceChange(selectedDevice: any) {
    this.viewQRcode(selectedDevice);
    this.deviceName = selectedDevice

  }
  compareFn(item1: any, item2: any): boolean {
    return item1.serial_number === item2.serial_number;
  }


  qrCodeValue: string;
  flagDownQr = false
  viewQRcode(dataQR: any): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log(dataQR);
      this.qrCodeValue = '';
      this.flagDownQr = false;
      const data = {
        user_id: this.idUser,
        token: this.userToken,
        mid: dataQR.serial_number ? dataQR.serial_number : dataQR,
        qrcodeType: 11
      };

      this.apiService.apiPostToPaydee('api/mbpaybox/create-qr', data).subscribe({
        next: res => {
          this.qrCodeValue = res['data']['qr_string'] ? res['data']['qr_string'] : '';
          this.flagDownQr = true;
          this.apiService.showToastMobile(this.translateService.instant('success'), this.translateService.instant("Tạo QR thành công"));
          this.eventService.setLoading(false);
          resolve(this.qrCodeValue);
        },
        error: e => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "warning",
            title: "Có lỗi xảy ra"
          });
          this.eventService.setLoading(false);
          reject(e);
        }
      });
    });
  }
  downloadQRCode() {
    const elementToDownload = document.querySelector('.qr-with-text').parentElement;

    if (elementToDownload) {
      html2canvas(elementToDownload as HTMLElement).then((canvas) => {
        const qrUrl = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = qrUrl;
        link.download = `QR_Code_${this.deviceName}.png`;
        link.click();
      });
    } else {
      console.error('Không tìm thấy phần tử.');
    }
  }
  printQRCode() {
    // Lấy phần tử cần in
    const elementToPrint = document.querySelector('.qr-with-text')?.parentElement;

    if (!elementToPrint) {
      console.error('Không tìm thấy phần tử để in.');
      return;
    }

    html2canvas(elementToPrint).then((canvas) => {
      const qrUrl = canvas.toDataURL('image/png');

      const printWindow = window.open('', '_blank');
      printWindow?.document.write(`
        <html>
          <head>
            <title>In QR Code</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }
                .print-container {
                  text-align: center;
                  width: 100%;
                  max-width: 450px; 
                }
                img {
                  width: 100%; 
                  height: auto;
                }
                @page {
                  margin: 0; /* Bỏ margin của trang */
                }
              }
            </style>
          </head>
          <body>
            <div class="print-container">
              <img src="${qrUrl}" />
              <!-- Bỏ phần tên ở dưới -->
            </div>
          </body>
        </html>
      `);
      printWindow?.document.close();
      printWindow?.addEventListener('load', () => {
        printWindow?.print();
        printWindow?.close();
      });
    });
  }



  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      serial_number: [data.serial_number, [Validators.required]],
      quantity: [data.quantity - data.totalAttachedDevice],
      customer_name: [data.customer_name],
      workspace_id: [data.workspace_id],
      phone: [data.phone],
      address: [data.address],
      data: [`${data.customer_name || ''} - ${data.phone || ''} - ${data.address || ''}`],
    })
  }


  onInputBlur() {
    this.eventService.setLoading(true);
    if (this.accountNumber === "") {
      this.listAccount = [];
      this.bank_accountName = "";
      this.eventService.setLoading(false);
      return;
    }
    const data = {
      partner_id: this.idUser,
      accountNumber: this.accountNumber,
      token: this.userToken,
    };

    this.apiService.apiPostToPaydee("prod/bank/account-info", data).subscribe(
      (response: any) => {
        if (
          response.ResponseData.errorCode === "200" &&
          !response.ResponseData.data
        ) {
          this.apiService.showToastMobile(
            "error",
            this.translateService.instant("Không tìm thấy tài khoản")
          );
        } else if (response.ResponseData.data) {
          this.apiService.showToastMobile(
            this.translateService.instant("success"),
            this.translateService.instant("Tìm kiếm thành công")
          );
          this.bank_accountName = response.ResponseData.data.accountName;
        } else {
          this.apiService.showToastMobile(
            "error",
            this.translateService.instant(
              "Có lỗi xảy ra khi tìm kiếm tài khoản"
            )
          );
        }

        this.eventService.setLoading(false);
      },
      (error) => {
        console.error("Error occurred:", error);
        this.apiService.showToastMobile(
          "error",
          this.translateService.instant("Lỗi kết nối. Vui lòng thử lại sau.")
        );
        this.eventService.setLoading(false);
      }
    );
  }

  updateStore() {
    const data = {
      phone: this.phoneNumber,
      name: this.storeName,
      address: this.storeAddress,
      bankName: "970422",
      accountNumber: this.accountNumber,
      bankAccountName: this.bank_accountName,
    };
    this.eventService.setLoading(true);
    this.apiService.editItem("workspaces-v2", this.id, data).subscribe({
      next: (res) => {
        this.apiService.showToastMobile(
          "success",
          this.translateService.instant("FORM.Success")
        );

        this.modalService.dismissAll();
        this.fetchData()
        this.searching = true;
        this.eventService.setLoading(false);
      },
      error: (e) => {
        if (e) {
          this.apiService.showToastMobile(
            "error",
            this.translateService.instant(e)
          );
        } else {
          this.apiService.showToastMobile(
            "error",
            this.translateService.instant("Sửa thông tin thất bại")
          );
        }
        this.eventService.setLoading(false);
      },
    });
  }

  //search member
  submitFilter() {
    this.getByPhone();
  }

  phoneResultsName: string = "";
  phoneResultsAddress: string = "";
  filterNameTable = "users/get-by-phone";
  getByPhone() {
    const requestData = {
      phone: this.phone,
    };
    this.eventService.setLoading(true);
    this.apiService
      .getbyphone(
        this.filterNameTable,
        requestData
        // + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
      )
      .subscribe({
        next: (res) => {
          const dataPhone = res["data"];
          this.phoneResultsName = dataPhone["full_name"]
            ? dataPhone["full_name"]
            : "";
          this.phoneResultsAddress = dataPhone["address"]
            ? dataPhone["address"]
            : "";

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "success",
            title: this.translateService.instant("FORM.Success"),
          });
          this.searching = true;
          this.eventService.setLoading(false);
        },
        error: (e) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "error",
            title: this.translateService.instant("Số điện thoại không tồn tại"),
          });
          this.eventService.setLoading(false);
        },
      });
  }
  makeForm2(d?) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      // id: [data.id],
      // imei : [data.imei, [Validators.required]],
      // description: [data.description],
      // price: [data.price || 0, [Validators.required]],
      // model : [data.model, [Validators.required]],
      // image_url: [data.image_url, [Validators.required]],
      device_id: [data.device_id, [Validators.required]],
      bankaccount_id: [data.bankaccount_id, [Validators.required]],
      // type: [data.type, [Validators.required]],
      // currency: [data.currency || 'VND'],
    });
  }

  makeFormMember(d?) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      phone: [data.phone, [Validators.required]],
      type: [data.type, [Validators.required]],
      // currency: [data.currency || 'VND'],
      workspace_id: this.activatedRoute.snapshot.params["id"],
    });
  }

  allMoney: number = 0;
  fetchData2() {
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableName + this.id
        // + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
      )
      .subscribe({
        next: (res) => {
          this.data2 = res.transactions;
          this.listData2 = this.data2;
          this.totalCount1 = res.length;
          this.allMoney = this.data2.reduce(
            (acc, current) => acc + current.total,
            0
          );
          // // this.totalCount = res.meta.total
          this.eventService.setLoading(false);
        },
      });
  }

  list_serial_number = [];

  fetchDataDevices(params = "") {
    params = "&order=updated_at&direction=desc";
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableName2 +
        `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
      )
      .subscribe({
        next: (res) => {
          // this.listData = res.workspaces
          this.listDataDivices = res["devices"] ? res["devices"] : [];
          this.listDataDivices.forEach((item) => {
            if (item["id"]) {
              this.list_serial_number.push(item["id"]);
            }
          });
          this.totalCount = res.length;
          this.eventService.setLoading(false);
        },
      });
  }

  openModalLink(contentLink) {
    this.submitted = false;
    this.formData2 = this.makeForm2({ language: [{}] });
    let a = this.modalService.open(contentLink, {
      centered: true,
      scrollable: true,
      size: "lg",
    });
  }

  tableName3 = "bankaccounts/active";
  list_id_account = [];
  listDataBankAccount = [];
  submitted: boolean;
  tableName4 = "workspaces/device-and-bankaccount";

  fetchDataBankAccount(params = "") {
    params = "&order=updated_at&direction=desc";
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableName3 +
        `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
      )
      .subscribe({
        next: (res) => {
          this.listDataBankAccount = res["bankaccounts"]
            ? res["bankaccounts"]
            : [];

          this.listDataBankAccount.forEach((item) => {
            if (item["id"]) {
              this.list_id_account.push(item["id"]);
            }
          });

          this.totalCount = res.length;
          // // this.totalCount = res.meta.tota
          this.eventService.setLoading(false);
        },
      });
  }

  saveData2() {
    this.submitted = true;
    if (this.formData2.valid) {
      const value = this.formData2.value;
      // value['status'] = value['status'] == true ? 0 : 1
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true);
      if (value.id) {
        this.apiService.editItem(this.tableName4, value.id, value).subscribe({
          next: (res) => {
            this.submitted = false;
            this.formData2.reset();
            this.fetchDataDevices();
            this.fetchDataBankAccount();
            this.modalService.dismissAll();
            this.apiService.showToast(
              this.translateService.instant("FORM.Success"),
              this.translateService.instant("FORM.SuccessMessage"),
              "success"
            );
            this.eventService.setLoading(false);
          },
          error: (e) => {
            this.apiService.showToast(
              this.translateService.instant("FORM.Error"),
              this.translateService.instant("Thêm không thành công"),
              "error"
            );
            this.eventService.setLoading(false);
          },
        });
      } else {
        this.apiService.addItem(this.tableName4, value).subscribe({
          next: (res) => {
            this.fetchDataDevices();
            this.fetchDataBankAccount();
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "success",
              title: this.translateService.instant("FORM.Success"),
            });

            this.eventService.setLoading(false);
            this.modalService.dismissAll();
          },
          error: (e) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "error",
              title: this.translateService.instant("Thêm không thành công"),
            });
            this.eventService.setLoading(false);
            // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
            // this.eventService.setLoading(false)
          },
        });
      }
    } else {
      console.log("invalid", this.formData2);
      Object.values(this.formData2.controls).forEach((control) => {
        if (control.status == "INVALID") {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  get form() {
    return this.formDataMember.controls;
  }

  formDataMember: FormGroup;
  listType = ["Trưởng nhóm", "Phó cửa hàng", "Thành viên"];
  tableNameMember = "members";

  openModalMember(contentMember: any) {
    this.submitted = false;
    this.formDataMember = this.makeFormMember({ language: [{}] });

    const leader = this.listMember.find((item) => item.type === "Trưởng nhóm");
    this.dataMember = {
      id: leader?.id
    };

    this.formUpdateDevice = this.formBuilder.group({
      user_id: [{ value: this.dataMember.id || '', disabled: true }],
      terminalID: [''],
      serial_number: [''],
      service_package: [''],
      activation_date: ['']
    });


    let a = this.modalService.open(contentMember, {
      centered: true,
      scrollable: true,
      size: "lg",
    });
  }

  listDataMember = [];

  fetchDataMember() {
    // params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableNameMember +
        `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`
      )
      .subscribe({
        next: (res) => {
          // this.listData = res.members
          this.listDataMember = res["members"] ? res["members"] : [];
          this.totalCount = res["pagination"]["totalCount"]
            ? res["pagination"]["totalCount"]
            : "";
          this.eventService.setLoading(false);
        },
      });
  }

  saveDataMember() {
    this.submitted = true;
    if (this.formDataMember.valid) {
      const value = this.formDataMember.value;
      // value['status'] = value['status'] == true ? 0 : 1
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true);
      if (value.id) {
        this.apiService
          .editItem(this.tableNameMember, value.id, value)
          .subscribe({
            next: (res) => {
              this.submitted = false;
              this.formDataMember.reset();
              this.fetchDataMember();
              this.modalService.dismissAll();
              this.apiService.showToast(
                this.translateService.instant("FORM.Success"),
                this.translateService.instant("FORM.SuccessMessage"),
                "success"
              );
              this.eventService.setLoading(false);
            },
            error: (e) => {
              this.apiService.showToast(
                this.translateService.instant("FORM.Error"),
                this.translateService.instant("Thêm không thành công"),
                "error"
              );
              this.eventService.setLoading(false);
            },
          });
      } else {
        this.apiService.addItem(this.tableNameMember, value).subscribe({
          next: (res) => {
            this.fetchDataMember();
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "success",
              title: this.translateService.instant("FORM.Success"),
            });
            window.location.reload();
            this.eventService.setLoading(false);
            this.modalService.dismissAll();
          },
          error: (e) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "error",
              title: this.translateService.instant("Thêm không thành công"),
            });
            this.eventService.setLoading(false);
            // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
            // this.eventService.setLoading(false)
          },
        });
      }
    } else {
      console.log("invalid", this.formDataMember);
      Object.values(this.formDataMember.controls).forEach((control) => {
        if (control.status == "INVALID") {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  updateDevice(): void {
    // Xử lý cập nhật thiết bị
    console.log(this.formUpdateDevice.value);
  }

  openModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  onDeleteDevice(device: any) {
    Swal.fire({
      title: this.translateService.instant('FORM.AreYouSure'),
      text: this.translateService.instant('Bạn có chắc chắn muốn xóa thiết bị này?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: this.translateService.instant('FORM.YesDelete')
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventService.setLoading(true);
        // Gọi API xóa thiết bị
        this.apiService.deleteItem('devices', device.id).subscribe({
          next: (res) => {
            Swal.fire(
              this.translateService.instant('FORM.Success'),
              this.translateService.instant('Xóa thiết bị thành công'),
              'success'
            );
            // Refresh lại danh sách thiết bị
            this.fetchDataDevices();
            this.eventService.setLoading(false);
          },
          error: (err) => {
            this.eventService.setLoading(false);
            Swal.fire(
              this.translateService.instant('FORM.Error'),
              this.translateService.instant('FORM.ErrorMessage'),
              'error'
            );
          }
        });
      }
    });
  }
}
