/// <reference types="@types/googlemaps" />


import { EventService } from './../../core/services/event.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// import { latLng, tileLayer } from 'leaflet';

import { Stat, Chat, Transaction, Data } from './dashboard.model';

// import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, transactions } from './data';
// import { ChartType } from '../chart/chartist/chartist.model';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
// import { HttpClient } from '@angular/common/http';
// import { catchError, map } from 'rxjs/operators';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';



// import { ChartComponent } from "ng-apexcharts";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {
    // mapOptions = {
    //     mapTypeId: "satellite"
    // };
    // term: any;
    // chatData: Chat[];
    transactions: Transaction[];
    // statData: Stat[];

    apiLoaded: Observable<boolean>;

    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;



    constructor(
        public formBuilder: FormBuilder,
        private apiService: ApiService,
        private eventService: EventService,
        private router: Router,
        private translate: TranslateService,
        private calendar: NgbCalendar,
        private translateService: TranslateService,
    ) {

    }
    role = JSON.parse(localStorage.getItem('currentUser')).role;
    pageSize = 10
    pageIndex = 1
    totalCount = 2
    tableName = 'dashboard'
    locations = [];
    data: Data
    listData = <any>[];
    selectedFilter: string = 'weekly';
    dataTotalRevenue = <any>{}
    chatSubmit: boolean;
    totalAmount
    listData10Trans = <any>[];
    listData5Largest = <any>[];
    listData5Smallest = <any>[];
    params = ''
    // Add this near your other properties
    searching = false
    dataRevenue: any = {
        total_transactions: 0,
        total_amount: 0
    }
    AVG: any
    apiTimeout: any; // Add this property to handle the timeout



    formData: FormGroup;

    listType = []

    loadingChart = true


    // click(e) {
    //     e.stopPropagation();
    //     console.log(e)
    // }

    // selectedMarker

    // openInfoWindow(marker: MapMarker, data) {
    //     this.infoWindow.open(marker);
    //     this.selectedMarker = data
    //     console.log(this.selectedMarker)
    // }

    // chartOptions = {
    //     series: [
    //         {
    //             name: 'Doanh thu',
    //             type: 'area',
    //             data: []
    //         },
    //         {
    //             name: 'Giao dịch',
    //             type: 'line',
    //             data: []
    //         }
    //     ],
    //     chart: {
    //         height: 350,
    //         type: 'line',
    //     },
    //     stroke: {
    //         curve: 'smooth'
    //     },
    //     fill: {
    //         type: 'solid',
    //         opacity: [0.35, 1],
    //     },
    //     labels: [],
    //     markers: {
    //         size: 0
    //     },
    //     yaxis: [
    //         {
    //             title: {
    //                 text: 'VNĐ',
    //             },
    //         },
    //         {
    //             opposite: true,
    //             title: {
    //                 text: 'Giao dịch',
    //             },
    //         },
    //     ],
    //     tooltip: {
    //         shared: true,
    //         intersect: false,
    //         y: {
    //             formatter: function (y: number, { seriesIndex }: { seriesIndex: number }) {
    //                 if (typeof y !== "undefined") {
    //                     if (seriesIndex === 0) {
    //                         return y.toLocaleString('vi-VN') + " VNĐ";
    //                     } else if (seriesIndex === 1) {
    //                         return y.toFixed(0) + " giao dịch";
    //                     }
    //                 }
    //                 return y;
    //             }
    //         }
    //     }
    // };

    ngOnInit(): void {
        this.fetchDataDashBoard()
        // Xóa bỏ API update-status và các API không cần thiết khác
        // this.eventService.setLoading(false) - Không set loading ở đây, để API call xử lý
    }
    
    // fetchData(params = '') {
    //     params = '&order=updated_at&direction=desc'
    //     this.eventService.setLoading(true)
    //     this.apiService.getList('transactions/dashboard' + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`).subscribe({
    //         next: (res) => {
    //             this.listData = res
    //             this.listData5Largest = res.metadata.top5Largest
    //             this.listData5Smallest = res.metadata.top5Smallest
    //             this.listData10Trans = res.metadata.top10RecentTransactions
    //             this.totalCount = res.length
    //             this.eventService.setLoading(false)
    //         }
    //     })
    // };

    submitFilter() {
        console.log(this.filter)
        this.params = ''
        Object.keys(this.filter).forEach(key => {
            console.log(key + '-' + this.filter[key])
            if (this.filter[key]) {
                this.params += `&${key}=${this.filter[key]}`
            }
        })
            this.fetchDataDashBoard()
    }

    clearFilter() {
        this.filter.startDate = ''
        this.filter.endDate = ''
        this.searching = false
        this.fetchDataDashBoard()
    }

    // Thêm biến để lưu cache
    private cachedData: any = null;
    private cacheTime: number = 0;
    private cacheDuration = 5 * 60 * 1000; // 5 phút

    // Thêm biến này vào component
    dataLoading = true;
    
    fetchDataDashBoard() {
        this.dataLoading = true; // Bắt đầu loading
        
        if (this.apiTimeout) {
            clearTimeout(this.apiTimeout);
        }
        
        // Kiểm tra cache
        const now = Date.now();
        if (this.cachedData && (now - this.cacheTime < this.cacheDuration) && !this.params) {
            // Sử dụng dữ liệu từ cache
            this.dataRevenue = this.cachedData;
            this.AVG = this.dataRevenue?.total_amount / this.dataRevenue?.total_transactions;
            this.loadingChart = false;
            this.dataLoading = false; // Kết thúc loading khi dùng cache
            return;
        }
        
        this.apiTimeout = setTimeout(() => {
            this.apiService.getList('dashboard' + `?type=true${this.params}`).subscribe({
                next: (res) => {
                    this.dataRevenue = {
                        total_transactions: res.metadata.total_transactions,
                        total_amount: res.metadata.total_amount
                    }
                    
                    if (!this.params) {
                        this.cachedData = this.dataRevenue;
                        this.cacheTime = Date.now();
                    }
                    
                    this.AVG = this.dataRevenue?.total_amount / this.dataRevenue?.total_transactions
                    this.loadingChart = false
                    this.dataLoading = false; // Kết thúc loading khi có dữ liệu
                },
                error: e => {
                    if (e) {
                      this.apiService.showToastMobile('error', this.translateService.instant(e))
                    } else {
                      this.apiService.showToastMobile('error', this.translateService.instant('Gọi dữ liệu thất b'))
                    }
                    this.dataLoading = false; // Kết thúc loading khi có lỗi
                }
            })
        }, 300);
    }
    
    // start = 0
    // end = 0
    
    toTrans() {
        return this.router.navigate(['/transactions']);
    }
    
    // chartData = [
    //     {
    //         data: [],
    //         label: 'Doanh thu (VND)',
    //         backgroundColor: '#0866E5', // Màu nền mặc định
    //         borderColor: '#217FFF', // Màu viền mặc định
    //         borderWidth: 1,
    //         borderRadius: {
    //             topLeft: 10,
    //             topRight: 10,
    //             bottomLeft: 0,
    //             bottomRight: 0
    //         },
    //         borderSkipped: false,
    //         hoverBackgroundColor: '#000080', // Màu nền khi hover
    //         hoverBorderColor: '#000080', // Màu viền khi hover
    //     }
    // ];


    // chartLabels = [];


    // chartLegend = true;
    // chartType = 'bar';

    // convertToDate(payDate: string): Date {
    //     const year = parseInt(payDate.substring(0, 4), 10);
    //     const month = parseInt(payDate.substring(4, 6), 10) - 1;
    //     const day = parseInt(payDate.substring(6, 8), 10);
    //     const hours = parseInt(payDate.substring(8, 10), 10);
    //     const minutes = parseInt(payDate.substring(10, 12), 10);
    //     const seconds = parseInt(payDate.substring(12, 14), 10);
    //     return new Date(year, month, day, hours, minutes, seconds);
    // }

    filter = {
        startDate: "",
        endDate: "",
    }

    hoveredDate: NgbDate;
    fromNGDate: NgbDate;
    toNGDate: NgbDate;

    model: NgbDateStruct;
    date: { year: number, month: number };
    // Select2 Dropdown
    selectValue: string[];

    hidden: boolean = true
    selected: any;
    @Input() fromDate: Date;
    @Input() toDate: Date;
    @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();
    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromNGDate = date;
            this.fromDate = new Date(date.year, date.month - 1, date.day);
            this.selected = '';
            this.filter.startDate = ""
            this.filter.endDate = ""

        } else if (this.fromDate && !this.toDate && (date.after(this.fromNGDate) || date.equals(this.fromNGDate))) {
            this.toNGDate = date;
            this.toDate = new Date(date.year, date.month - 1, date.day);
            this.hidden = true;
            // this.selected = this.fromDate.toLocaleDateString() + '-' + this.toDate.toLocaleDateString();
            this.selected = moment(this.fromDate).format('DD/MM/YYYY') + '-' + moment(this.toDate).format('DD/MM/YYYY')
            this.filter.startDate = moment.utc(this.fromDate).local().format('YYYY-MM-DD')
            this.filter.endDate = moment.utc(this.toDate).local().format('YYYY-MM-DD')
            // this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
            console.log(this.filter)
            console.log(this.toDate)
            console.log(moment.utc(this.toDate).local().format('YYYY-MM-DD'))

            this.fromDate = null;
            this.toDate = null;
            this.fromNGDate = null;
            this.toNGDate = null;
        } else {
            this.fromNGDate = date;
            this.fromDate = new Date(date.year, date.month - 1, date.day);
            this.selected = '';
            this.filter.startDate = ""
            this.filter.endDate = ""
        }
    }
    /**
    * Is hovered over date
    * @param date date obj
    */
    isHovered(date: NgbDate) {
        return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
    }

    /**
     * @param date date obj
     */
    isInside(date: NgbDate) {
        return date.after(this.fromNGDate) && date.before(this.toNGDate);
    }

    /**
     * @param date date obj
     */
    isRange(date: NgbDate) {
        return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
    }

    /**
     * Select the today
     */
    selectToday() {
        this.model = this.calendar.getToday();
    }

    clearDate() {
        this.selected = ''
        this.filter.startDate = ''
        this.filter.endDate = ''
    }

}