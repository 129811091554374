import { Transaction } from './../dashboard/dashboard.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-search-transactions',
  templateUrl: './search-transactions.component.html',
  styleUrls: ['./search-transactions.component.scss'],
  providers: [DatePipe]
})
export class SearchTransactionsComponent implements OnInit{

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private apiService: ApiService,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private calendar: NgbCalendar,
    private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig,
    public translateService: TranslateService
    // private modalService: NzModalService,
    // private notification: NzNotificationService
) {
    _lightboxConfig.centerVertically = true
    _lightboxConfig.wrapAround = true
    _lightboxConfig.showImageNumberLabel = true
    _lightboxConfig.disableScrolling = true
   
    
    
    // this.eventService.setLoading(true)
    // this.apiService.getList('reports').subscribe({

    //     next: res => {
    //         console.log(res);

    //         this.listType = res
    //         this.eventService.setLoading(false)

    //     },
    //     error: e => {
    //         alert('lỗi')
    //         this.eventService.setLoading(false)

    //     }
    // })
}

listType = []

formUpdate = {
    note: '',
    status: 0,
    id: 0,
    type_id: 0
}
breadCrumbItems
role = JSON.parse(localStorage.getItem('currentUser'))['role']
user = JSON.parse(localStorage.getItem('currentUser'))
workspace_id = JSON.parse(localStorage.getItem('workspace'))
type$ = this.apiService.getList('incident-type')
// location$ = this.apiService.getList('location')

searchTerm
listData = []
listDataDevices = []
listDataAssign = []

pageSize = 10
pageIndex = 1
totalCount = 0
tableName = 'prod/mbpaybox/list-transactions'
tableName2 = 'devices'
submitted: boolean
formData: FormGroup
convertedDates: Date[];

selectedBooking
selectedBookingTracks

_albums = []
params = ''
// selectTrans: listData.id
// get form() {
//     return this.formData.controls
// }
today =  new Date();
firstDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1)
lastDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0)
ngOnInit() {
    this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'PAGE.REPORT.BreadcrumbTitle', active: true }];
    this.fetchData()
   
}
filter = {
    type_id: -1,
    status: -1,
    fromDate: this.datePipe.transform(this.firstDayOfMonth, 'MM/dd/yyyy'),
    toDate: this.datePipe.transform(this.lastDayOfMonth, 'MM/dd/yyyy'),
    serialnumber: ''
  };
open(i) {
    this._lightbox.open(this._albums, i);
}

close(): void {
    this._lightbox.close();
}

openModalImage(data) {
    console.log(data)
}

onChangePage(e) {
    this.pageIndex = e
    this.fetchData()
}

searching = false

clearFilter() {
    this.selected = ''
    this.params = ''
    this.filter.serialnumber = ''
    this.filter.type_id = -1
    this.filter.status = -1
    this.filter.fromDate = this.datePipe.transform(this.firstDayOfMonth, 'MM/dd/yyyy')
    this.filter.toDate = this.datePipe.transform(this.lastDayOfMonth, 'MM/dd/yyyy')
    this.pageIndex = 1
    this.searching = false
    this.fetchData()
}

submitFilter() {
    
    this.params = ''
    Object.keys(this.filter).forEach(key => {
        console.log(key + '-' + this.filter[key])
        if (this.filter[key]) {
            this.params += `&${key}=${this.filter[key]}`
        }
    })
    if (this.params) {
        this.pageIndex = 1
        this.searching = true
        this.fetchData()
    }
}
  convertToDate(payDate: string): Date {
    const year = parseInt(payDate.substring(0, 4), 10);
    const month = parseInt(payDate.substring(4, 6), 10) - 1; 
    const day = parseInt(payDate.substring(6, 8), 10);
    const hours = parseInt(payDate.substring(8, 10), 10);
    const minutes = parseInt(payDate.substring(10, 12), 10);
    const seconds = parseInt(payDate.substring(12, 14), 10);
    return new Date(year, month, day, hours, minutes, seconds);
  }
    fetchData() {
        const data = {
            pageSize: this.pageSize,
            pageNumber: this.pageIndex,
            partner_id: this.user?.id,
            token: this.user?.user_token,
            fromDate: this.filter.fromDate,
            toDate: this.filter.toDate,
            workspace_id: this.workspace_id
        }
        this.eventService.setLoading(true)
        this.apiService.apiPostToPaydee(this.tableName ,data
        ).subscribe({
            next: res => {
                this.listData = res['ResponseData']?.['data'] ? res['ResponseData']?.['data'].map(item => ({
                    ...item,
                    payDate: this.convertToDate(item.payDate)
                })) : [];
                this.totalCount = this.listData.length
                this.eventService.setLoading(false)
            }
        })
    }

list_serial_number = []

fetchData1(params = '') {
    params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName2 +
        `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
        next: (res) => {
            this.listDataDevices = res.devices
            this.listDataDevices.forEach((item) => {
                if (item['id']) {
                    this.list_serial_number.push(item['id']);
                }
            })
            // // this.totalCount = res.meta.total
            this.eventService.setLoading(false)
        }
    })
}

getSerialNum(id) {
    let selectedItem = this.listDataDevices.filter(item => item.id == id)[0]
    console.log(id)
    return selectedItem.serial_number
}


openModal(content: any) {
    // this.apiService.successmsg()
    this.formData = this.makeForm()
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
}

onEdit(data, content) {
    console.log(data)
    this._albums = [...data.images].map(i => {
        const src = environment.imageHost + i.url;
        const classification = i.classification;
        const caption = i.filename;
        const thumb = src;
        return { src, caption, thumb, classification }
    })
    this.open(0)
}

viewDetails(data: any) {
    this.router.navigate(['transactions/details-transactions', data.id]); 
}
onImgError(event) {
    event.target.src = './assets/images/img-error.jpg'
}
handleClick(id: string): void {
    // Chuyển hướng đến trang "pagedetail" với tham số id
    this.router.navigate(['/pagedetail', id]);
}
indexPrice = 0
onDetailBooking(data, content) {
    if (data.id) {
        this.eventService.setLoading(true)
        this.apiService.getList(`incident/${data.id}`).subscribe({
            next: res => {
                this.selectedBooking = res
                this._albums = [...this.selectedBooking.images].map(i => {
                    const src = environment.imageHost + i.url;
                    const classification = i.classification;
                    const caption = i.filename;
                    const thumb = src;
                    return { src, caption, thumb, classification }
                })
                this.selectedBooking['imageClasstification'] = this._albums.find(i => i.classification)
                // console.log(this.selectedBooking)
                this.formUpdate.note = res.note
                this.formUpdate.status = res.status
                this.formUpdate.type_id = res.type_id
                this.formUpdate.id = res.id
                this.modalService.open(content, { centered: true, scrollable: true, windowClass: 'my-class' });
                this.selectedBooking['lat'] = 0
                this.selectedBooking['lng'] = 0
                // if (this.selectedBooking.location_other && this.selectedBooking.location_other.latitude && this.selectedBooking.location_other.longitude) {
                //     this.selectedBooking['lat'] = this.selectedBooking.location_other.latitude
                //     this.selectedBooking['lng'] = this.selectedBooking.location_other.longitude
                // } else if (this.selectedBooking.location_detail && this.selectedBooking.location_detail.lat && this.selectedBooking.location_detail.lng) {
                //     this.selectedBooking['lat'] = this.selectedBooking.location_detail.lat
                //     this.selectedBooking['lng'] = this.selectedBooking.location_detail.lng
                // }
                // this.center = this.selectedBooking
                this.eventService.setLoading(false)
            },
            error: e => {
                this.eventService.setLoading(false)
            }
        })
    }

}
onDetailB(data, content) {
    if (data.id) {
        this.eventService.setLoading(true)
        this.apiService.getList(`incident/${data.id}`).subscribe({
            next: res => {
                this.listData = res
                this.eventService.setLoading(false)
            },
            error: e => {
                this.eventService.setLoading(false)
            }
        })
    }

}
updateFormNote() {
    if (this.formUpdate.id) {
        this.eventService.setLoading(true)
        this.apiService.editItem(this.tableName, this.formUpdate.id, this.formUpdate).subscribe({
            next: res => {
                this.fetchData()

                this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
                this.eventService.setLoading(false)
                this.modalService.dismissAll()
            },
            error: e => {
                console.log(e)
                this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessage'), 'error')
                this.eventService.setLoading(false)
            }
        })

    }
}

getDetail(id) {

}

getListDevices(params = '') {
    params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName2 +
        `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
    ).subscribe({
        next: (res) => {
            // console.log(res)
        }
    })

}

makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
        id: [data.id],
        name: [data.name, [Validators.required]],
        description: [data.description],
        price: [data.price || 0, [Validators.required]],
        status: [data.status || false],
        image_url: [data.image_url, [Validators.required]],
        media_id: [data.media_id],
        parent_id: [data.parent_id || 0],
        currency: [data.currency || 'VND'],
    })
}

saveData() {
    this.submitted = true
    if (this.formData.valid) {
        const value = this.formData.value
        this.eventService.setLoading(true)
        if (value.id) {
            this.apiService.editItem(this.tableName, value.id, value).subscribe({
                next: res => {
                    this.fetchData()
                    let a = this.translateService.instant('PAGE.FORM.Error')
                    this.apiService.showToast(a, ('PAGE.FORM.SuccessMessage'), 'success')
                    this.eventService.setLoading(false)
                },
                error: e => {
                    this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                    this.eventService.setLoading(false)
                }
            })
        } else {
            this.apiService.addItem(this.tableName, value).subscribe({
                next: res => {
                    this.fetchData()
                    this.apiService.showToast('', 'Thêm mới thông tin thành công', 'success')
                    this.eventService.setLoading(false)
                    this.modalService.dismissAll()
                },
                error: e => {
                    this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                    this.eventService.setLoading(false)
                }
            })
        }

    } else {
        console.log('invalid', this.formData)
        Object.values(this.formData.controls).forEach(control => {
            if (control.status == 'INVALID') {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
            }
        });
    }
}




hoveredDate: NgbDate;
fromNGDate: NgbDate;
toNGDate: NgbDate;

model: NgbDateStruct;
date: { year: number, month: number };
// Select2 Dropdown
selectValue: string[];

hidden: boolean = true
selected: any;
@Input() fromDate: Date;
@Input() toDate: Date;
@Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
      this.filter.fromDate = this.datePipe.transform(this.firstDayOfMonth, 'MM/dd/yyyy');
      this.filter.toDate = this.datePipe.transform(this.lastDayOfMonth, 'MM/dd/yyyy');
    } else if (this.fromDate && !this.toDate && (date.after(this.fromNGDate) || date.equals(this.fromNGDate))) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      this.selected = moment(this.fromDate).format('DD/MM/YYYY') + '-' + moment(this.toDate).format('DD/MM/YYYY');
      this.filter.fromDate = moment(this.fromDate).format('MM/DD/YYYY');
      this.filter.toDate = moment(this.toDate).format('MM/DD/YYYY');
      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
      this.filter.fromDate = this.datePipe.transform(this.firstDayOfMonth, 'MM/dd/yyyy');
      this.filter.toDate = this.datePipe.transform(this.lastDayOfMonth, 'MM/dd/yyyy');
    }
  }
/**
* Is hovered over date
* @param date date obj
*/
isHovered(date: NgbDate) {
    return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
}

/**
 * @param date date obj
 */
isInside(date: NgbDate) {
    return date.after(this.fromNGDate) && date.before(this.toNGDate);
}

/**
 * @param date date obj
 */
isRange(date: NgbDate) {
    return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
}

/**
 * Select the today
 */
selectToday() {
    this.model = this.calendar.getToday();
}

clearDate() {
    this.selected = ''
    this.filter.fromDate = this.datePipe.transform(this.firstDayOfMonth, 'MM/dd/yyyy')
    this.filter.toDate = this.datePipe.transform(this.lastDayOfMonth, 'MM/dd/yyyy')
}


}
