<div class="card">
  <div class="card-body">
    <div class="mb-0">
      <div class="mb-2">
        <div class="row modal-content border border-dark ml-0 mb-4 p-0">
          <div class="col col-md-12">
            <div class="d-md-flex">
              <div class="col col-md-6 m-0 p-1">
                <div class="card-body rounded-top row">
                  <div class="col-4">
                    <img src="../../../assets/images/store.png" class="img-fluid" alt="image" />
                  </div>
                  <div class="col-8 p-2">
                    <div class="d-flex">
                      <h2 class="modal-title workspacename fs-5 font-weight-bold">
                        {{ data.workspace_name | translate }}
                      </h2>
                      <p class="ml-4 p-3 text-success">Đang hoạt động</p>
                    </div>
                    <p class="">
                      {{ "Ngày tạo: " | translate }}
                      {{ data.created_at | date : "dd/MM/yyyy" }}
                    </p>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <div class="modal-footer flex-nowrap">
                      <span class="col-md-6 m-0"># {{ data.code }}</span>
                      <span class="col-md-6 m-0">Thành viên: {{ countMember }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="g-3 card-body row row-cols-1 row-cols-md-2">
                                        <div class="col">
                                            <br/>
                                        </div>                                    
                                    </div> -->
              </div>
              <div class="col col-md-6 p-4 border-left my-3">
                <div class="container">
                  <div class="g-3 row row-cols-1 row-cols-md-2">
                    <div class="col">
                      <div class="card shadow-sm border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">
                            Tổng số tiền
                          </p>
                          <p class="text-center text-success font-weight-bold m-0">
                            {{
                            allMoney | currency : "VND" : "symbol" : "1.0-0"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">
                            Số giao dịch
                          </p>
                          <p class="text-center font-weight-bold m-0">
                            {{ countTransaction || 0 }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm border border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">Thiết bị</p>
                          <p class="text-center font-weight-bold m-0">
                            {{ data.serial_number || "Chưa có" }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm border border-warning">
                        <div class="mt-2 mb-2">
                          <p class="text-center font-weight-bold">Version</p>
                          <p class="text-center font-weight-bold m-0">
                            {{ data.version || "Chưa có" }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" justify-content-between" *ngIf="role == 'admin'">
          <button class="btn btn-outline-success mb-2" (click)="openModalMember(contentUpdateDevice)">
            <i class="mdi mdi-plus mr-2"></i>
            {{ "Liên kết thiết bị" | translate }}
          </button>
        </div>
        <div class="d-flex justify-content-between d-flex" *ngIf="role == 'user'">
          <!--  -->

          <button *ngIf="leader === fullname" class="btn btn-outline-warning mb-2" (click)="openModalLink(contentLink)">
            {{ "Sửa thông tin cửa hàng" | translate }}
          </button>

          <button *ngIf="leader === fullname" class="btn btn-outline-warning mb-2" (click)="onEdit(data, contentQR)">
            <i class="mdi mdi-qrcode-scan"></i>

            {{ "Hiển thị QR cửa hàng" | translate }}
          </button>

          <button *ngIf="leader === fullname" class="btn btn-outline-success mb-2"
            (click)="openModalMember(contentMember)">
            <i class="mdi mdi-plus mr-2"></i>
            {{ "Thêm thành viên" | translate }}
          </button>
        </div>
        <div class="">
          <div class="col col-md-12 bg-success border rounded-top">
            <span class="text-white" style="font-size: 20px">Danh sách thành viên</span>
          </div>
          <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap no-footer dtr-inline">
              <thead>
                <tr class="text-center bg-table">
                  <th>{{ "#" | translate }}</th>
                  <th>{{ "Họ tên" | translate }}</th>
                  <th>{{ "Số điện thoại" | translate }}</th>
                  <th>{{ "Địa chỉ" | translate }}</th>
                  <th>{{ "Vai trò" | translate }}</th>
                  <th>{{ "Ngày vào" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" *ngFor="let dataMember of listMember; let i = index">
                  <td>{{ pageSize * (pageIndex - 1) + i + 1 }}</td>

                  <!-- <td *ngIf="role ==='admin'">{{data.phone}}</td> -->
                  <!-- <td *ngIf="role ==='admin'">{{data.workspace_name}}</td> -->
                  <td>{{ dataMember.full_name }}</td>
                  <td>{{ dataMember.phone }}</td>
                  <td>{{ dataMember.address }}</td>
                  <td>{{ dataMember.type }}</td>
                  <td>{{ dataMember.created_at | date : "dd/MM/yyyy" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br />

        <div class="">
          <div class="col col-md-12 bg-success border rounded-top">
            <span class="text-white" style="font-size: 20px">Danh sách giao dịch</span>
          </div>
          <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap no-footer dtr-inline">
              <thead>
                <tr class="text-center bg-table">
                  <th>{{ "#" | translate }}</th>
                  <th>{{ "Mã giao dịch" | translate }}</th>
                  <th>{{ "Mã thiết bị" | translate }}</th>
                  <th>{{ "Số tài khoản" | translate }}</th>
                  <th>{{ "Số tiền" | translate }}</th>
                  <!-- <th>{{'Trạng thái' | translate}}</th> -->
                  <th>{{ "Ngày giao dịch" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" *ngFor="let data2 of listData2; let i = index">
                  <td>{{ pageSize * (pageIndex - 1) + i + 1 }}</td>

                  <!-- <td *ngIf="role ==='admin'">{{data.phone}}</td> -->
                  <!-- <td *ngIf="role ==='admin'">{{data.workspace_name}}</td> -->
                  <td>{{ data2.code }}</td>
                  <td>{{ data2.serial_number }}</td>
                  <td>{{ data2.account_number }}</td>
                  <td>{{ data2.total }}</td>
                  <!-- <td>    
                                        <span class="font-size-12 badge badge-danger" *ngIf="data2.status == '0'">{{'Từ chối' |
                                            translate}}</span>
                                        <span class="font-size-12 badge badge-warning" *ngIf="data2.status == '2'">{{'Đang xử lý' |
                                            translate}}</span>
                                        <span class="font-size-12 badge badge-success" *ngIf="data2.status == '1'">{{'Đã duyệt' |
                                            translate}}</span>
                                    </td> -->
                  <td>{{ data2.created_at | date : "dd/MM/yyyy" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="">
          <div class="col col-md-12 bg-success border rounded-top">
            <span class="text-white" style="font-size: 20px">Danh sách thiết bị liên kết</span>
          </div>
          <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap no-footer dtr-inline">
              <thead>
                <tr class="text-center bg-table">
                  <th>{{ "#" | translate }}</th>
                  <th>{{ "Mã khách hàng" | translate }}</th>
                  <th>{{ "Mã cửa hàng" | translate }}</th>
                  <th>{{ "Mã thiết bị" | translate }}</th>
                  <th>{{ "Gói dịch vụ" | translate }}</th>
                  <th>{{ "Ngày kích hoạt" | translate }}</th>
                  <th>{{ "Quản lý" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let device of listDataDivices; let i = index">
                  <td>{{pageSize * (pageIndex - 1) + i + 1}}</td>
                  <td>{{device.user_id}}</td>
                  <td>{{device.terminalID}}</td>
                  <td>{{device.serial_number}}</td>
                  <td>{{device.service_package}}</td>
                  <td>{{device.activation_date | date:'dd/MM/yyyy'}}</td>
                  <td class="py-2 table-column">
                    <div class="d-flex" style="gap: 5px;">
                      <a class="font-size-18" href="javascript: void(0);" ngbTooltip="{{'Sửa' | translate}}" placement="bottom-left">
                        <i class="mdi mdi-account-edit"></i>
                      </a>
                      <a class="font-size-18 text-danger" ngbTooltip="{{'Xóa thiết bị' | translate}}" placement="bottom-left" (click)="onDeleteDevice(device)">
                        <i class="mdi mdi-delete"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <!--  -->
      <!-- <button class="btn btn-success mb-2" (click)="openModal(content)">
              <i class="mdi mdi-plus mr-2"></i> Th4m dịch vụ
          </button> -->
    </div>
  </div>
</div>

<ng-template #contentLink role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Thay đổi thông tin cửa hàng" | translate }}</h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
            Mật khẩu không được bỏ trống.
        </div> -->
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <div class="container mt-3">
        <form (ngSubmit)="updateStore()">
          <div class="mb-3">
            <label for="storeName" class="form-label required_label">Tên cửa hàng</label>
            <input type="text" [(ngModel)]="storeName" name="storeName" class="form-control" id="storeName"
              placeholder="  Nhập tên cửa hàng" />
          </div>
          <div class="mb-3">
            <label for="address" class="form-label required_label">Địa chỉ</label>
            <input type="text" [(ngModel)]="storeAddress" name="address" class="form-control" id="address"
              placeholder="Nhập địa chỉ cụ thể" />
          </div>

          <div class="mb-3">
            <label class="form-label required_label">{{
              "Ngân hàng" | translate
              }}</label>
            <ng-select [(ngModel)]="binNumber" name="binNumber" aria-label="Default select example">
              <ng-option value="" hidden>Chọn ngân hàng</ng-option>
              <ng-option [value]="item.id" *ngFor="let item of listBankname">{{
                item.name
                }}</ng-option>
            </ng-select>
          </div>

          <div *ngIf="listAccount.length > 0; else noListAccount" class="mb-3">
            <label class="form-label required_label">{{
              "Số tài khoản" | translate
              }}</label>
            <ng-select [(ngModel)]="accountNumber" name="accountNumber" aria-label="Default select example"
              (blur)="onInputBlur()" (change)="onInputBlur()">
              <ng-option value="" hidden>Thêm tài khoản mới</ng-option>
              <ng-option [value]="item.account_number" *ngFor="let item of listAccount">{{ item.account_number
                }}</ng-option>
            </ng-select>
          </div>

          <ng-template #noListAccount>
            <div class="mb-3">
              <label class="col-form-label required_label">{{
                "Số tài khoản" | translate
                }}</label>
              <div>
                <input type="text" class="form-control" [(ngModel)]="accountNumber" name="accountNumber"
                  placeholder="Nhập số tài khoản" (blur)="onInputBlur()" />
              </div>
            </div>
          </ng-template>

          <div class="mb-3" *ngIf="bank_accountName">
            <div class="form-group">
              <label class="col-form-label required_label">{{
                "Chủ tài khoản" | translate
                }}</label>
              <div>
                <input type="text" [ngModel]="bank_accountName" name="bank_accountName" class="form-control" readonly
                  disabled />
              </div>
            </div>
          </div>

          <div class="alert alert-warning d-flex align-items-center" role="alert">
            <i class="bi bi-exclamation-triangle-fill me-2"></i>
            <span>Số tài khoản này dùng để nhận tiền bán hàng và tạo mã QR nhận
              tiền với tiện ích vượt trội duy nhất bởi MB Bank</span>
          </div>

          <div class="d-flex justify-content-around">
            <button type="button" class="btn btn-secondary btn-lg mr-3" style="width: 150px"
              (click)="modal('close click')">
              {{ "Đóng" | translate }}
            </button>
            <button bmitype="sut" class="btn btn-success btn-lg" style="width: 150px">
              {{ "Cập nhật" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentMember role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ "Thêm thành viên" | translate }}</h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
            Mật khẩu không được bỏ trống.
        </div> -->
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveDataMember()" [formGroup]="formDataMember">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">{{
              "Số điện thoại" | translate
              }}</label>
            <div class="col-md-8">
              <input type="phone" class="form-control" formControlName="phone"
                [ngClass]="{ 'is-invalid': submitted && form.phone.errors }" [(ngModel)]="phone" />
            </div>
            <div class="col-md-2">
              <button type="button" class="text-white btn btn-info" (click)="submitFilter()" style="width: inherit">
                Tìm kiếm
              </button>
            </div>
          </div>
          <div class="row col-md-12 m-4" style="padding-left: 7rem" *ngIf="searching">
            <img class="mr-4 rounded-circle avtFilter" src="assets/images/users/avatar-9.png" alt="Avatar" />
            <div class="d-flex flex-column align-items-start justify-content-center">
              {{ phoneResultsName }} - {{ phoneResultsAddress }}
            </div>
          </div>
          <div class="form-group row col-md-12" *ngIf="searching">
            <label class="col-md-2 col-form-label required_label">{{
              "Vai trò" | translate
              }}</label>

            <div class="col-md-10">
              <select class="form-select" aria-label="Default select example" class="form-control"
                formControlName="type" [ngClass]="{ 'is-invalid': submitted && form.type.errors }">
                <option value="{{ item }}" *ngFor="let item of listType">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- <div class="text-right">
                    <button type="button" class="btn btn-secondary btn-sm mr-3"
                        (click)="modal('close click')">{{'Đóng' | translate}}</button>
                    <button type="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                </div> -->
        <div class="text-center" *ngIf="searching">
          <button type="button" class="btn btn-secondary btn-lg mr-3" style="width: 150px"
            (click)="modal('close click')">
            {{ "Đóng" | translate }}
          </button>
          <button bmitype="sut" class="btn btn-success btn-lg" style="width: 150px">
            {{ "Lưu" | translate }}
          </button>
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                    (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                    (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- <ng-template > -->

<!-- </ng-template> -->
<ng-template #contentQR role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Hiển thị QR
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">{{'Serial' | translate}}</label>
            <div class="col-md-10">
              <ng-select [items]="listDevicesQr" bindLabel="serial_number" bindValue="serial_number"
                formControlName="serial_number" (change)="onDeviceChange($event)"
                placeholder="{{ 'Chọn thiết bị' | translate }}"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}" [compareWith]="compareFn">
              </ng-select>
            </div>
          </div>
          <div class="form-group row col-md-12">
            <div class="col-md-12 d-block justify-content-center">
              <div class="d-flex justify-content-center">
                <div class="borderQR d-flex flex-column align-items-center" *ngIf="flagDownQr" #qrContainer>
                  <div class="qr-with-text canvas-container">
                    <qr-code class="custom-qr-code" [value]="qrCodeValue" [size]="400" [errorCorrectionLevel]="'M'">
                    </qr-code>
                    <div class="text-center user-info" *ngIf="flagDownQr">
                      <h3 class="font-weight-bold">
                        {{dataUser.terminal_bank_acc_name}}
                      </h3>
                      <h3 class="font-weight-bold">
                        {{dataUser.terminal_bank_acc}}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <div class="col-12 col-md-3 p-0">
                  <button class="btn-viettel col" (click)="downloadQRCode()">
                    <span class="m-auto">Tải xuống mã QR</span>
                  </button>
                  <button class="btn-viettel col mt-2" (click)="printQRCode()">
                    In QR Code
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button type="button" class="btn btn-secondary btn-lg mr-3" style="width: 150px;"
            (click)="modal('close click')">
            {{'Đóng' | translate}}
          </button>

        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentUpdateDevice role="document" let-modal="close">
  <div class="modal-header bg-light border-bottom">
    <h5 class="modal-title text-primary font-weight-bold">{{ "Cập nhật liên kết thiết bị" | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="text-left">
      <form (ngSubmit)="updateDevice()" [formGroup]="formUpdateDevice">
        <div class="row">
          <!-- User ID -->
          <div class="form-group row col-md-12 align-items-center">
            <label class="col-md-3 col-form-label required_label text-muted font-weight-semibold">
              {{ "Mã khách hàng" | translate }}
            </label>
            <div class="col-md-9">
              <input type="text" class="form-control form-control-lg shadow-sm" [value]="dataMember.id || ''"
                readonly />
            </div>
          </div>

          <!-- Terminal ID -->
          <div class="form-group row col-md-12 align-items-center">
            <label class="col-md-3 col-form-label required_label text-muted font-weight-semibold">
              {{ "Mã cửa hàng" | translate }}
            </label>
            <div class="col-md-9">
              <input type="text" class="form-control form-control-lg shadow-sm" formControlName="terminalID"
                [(ngModel)]="terminalID" />
            </div>
          </div>

          <!-- Serial Number -->
          <div class="form-group row col-md-12 align-items-center">
            <label class="col-md-3 col-form-label required_label text-muted font-weight-semibold">
              {{ "Mã thiết bị" | translate }}
            </label>
            <div class="col-md-9">
              <input type="text" class="form-control form-control-lg shadow-sm" formControlName="serial_number"
                [(ngModel)]="serialNumber" />
            </div>
          </div>

          <!-- Service Package -->
          <div class="form-group row col-md-12 align-items-center">
            <label class="col-md-3 col-form-label required_label text-muted font-weight-semibold">
              {{ "Gói dịch vụ" | translate }}
            </label>
            <div class="col-md-9">
              <select class="form-select form-control-lg shadow-sm long-dropdown" formControlName="service_package"
                [(ngModel)]="servicePackage">
                <option value="" disabled selected>Chọn gói dịch vụ</option>
                <option value="LOAWIFI3M">LOAWIFI3M (3 Months)</option>
                <option value="LOAWIFI6M">LOAWIFI6M (6 Months)</option>
                <option value="LOAWIFI12M">LOAWIFI12M (12 Months)</option>
                <option value="LOA4G3M">LOA4G3M (3 Months)</option>
                <option value="LOA4G6M">LOA4G6M (6 Months)</option>
                <option value="LOA4G12M">LOA4G12M (12 Months)</option>
              </select>
            </div>
          </div>

          <!-- Activation Date -->
          <div class="form-group row col-md-12 align-items-center">
            <label class="col-md-3 col-form-label required_label text-muted font-weight-semibold">
              {{ "Ngày kích hoạt" | translate }}
            </label>
            <div class="col-md-9">
              <input type="date" class="form-control form-control-lg shadow-sm" formControlName="activation_date"
                [(ngModel)]="activationDate" />
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="text-center mt-5">
          <button type="button" class="btn btn-outline-secondary btn-lg mx-2 shadow-sm" style="width: 150px;"
            (click)="modal('close click')">
            {{ "Đóng" | translate }}
          </button>
          <button type="submit" class="btn btn-primary btn-lg mx-2 shadow-sm" style="width: 150px;">
            {{ "Cập nhật" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>