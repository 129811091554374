import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDate, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

interface BaasDevice {
  terminalID: string;
  serial_number: string;
  qrcodeID: string;
  owner: string;
  model: "4G" | "Wifi";
  service_package: 3 | 6 | 12;
  actived_date: string;
  expired_date: string;
  created_at: string;
}

@Component({
  selector: "app-workspace-baas",
  templateUrl: "./workspace-baas.component.html",
  styleUrls: ["./workspace-baas.component.scss"],
})
export class WorkspaceBaasComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  hidden = true;
  selected = "";

  listData: BaasDevice[] = [];
  page = 1;
  pageSize = 10;
  totalItems = 0;
  startIndex = 0;
  endIndex = 0;

  filter = {
    terminalID: "",
    serial_number: "",
    service_package: "",
    model: "",
    startDate: null as string | null,
    endDate: null as string | null,
    startActiveDate: null as string | null,
    endActiveDate: null as string | null,
  };
  searching = false;

  // Biến cho modal chỉnh sửa
  @ViewChild("editModal") editModal: any;
  @ViewChild("deleteModal") deleteModal: any;
  editedItem: BaasDevice = this.getEmptyDevice();
  isNewItem = true;
  selectedItem: BaasDevice | null = null;

  // Thêm các biến mới
  @ViewChild("importModal") importModal: any;
  selectedFile: File | null = null;

  // Thêm các biến mới cho date range kích hoạt
  hoveredActivationDate: NgbDate | null = null;
  fromActivationDate: NgbDate | null = null;
  toActivationDate: NgbDate | null = null;
  hiddenActivation = true;
  selectedActivation = "";

  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.submitFilter();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.selected = this.formatDateRange();
      this.filter.startDate = this.formatDate(this.fromDate);
      this.filter.endDate = this.formatDate(this.toDate);
      this.hidden = true;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  clearDate() {
    this.selected = "";
    this.fromDate = null;
    this.toDate = null;
    this.filter.startDate = null;
    this.filter.endDate = null;
  }

  formatDateRange(): string {
    if (this.fromDate && this.toDate) {
      const from = this.formatDate(this.fromDate);
      const to = this.formatDate(this.toDate);
      return `${from} - ${to}`;
    }
    return "";
  }

  formatDate(date: NgbDate): string {
    if (!date) return "";
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, "yyyy-MM-dd", "en");
  }

  loadData() {
    // const url = `${environment.host}baas-noti?page=${this.page}&limit=${this.pageSize}`;
    const url = `${environment.host}baas-noti`;
    const params = new HttpParams()
      .set("page", this.page.toString())
      .set("limit", this.pageSize.toString());
    this.http.get<any>(url).subscribe((response) => {
      if (response.status === 200) {
        this.listData = response.metadata.results.map((item: any) => ({
          terminalID: item.terminalID,
          serial_number: item.serial_number,
          qrcodeID: item.qrcodeId,
          owner: item.owner,
          model: item.model,
          service_package: item.service_package,
          actived_date: item.activation_date,
          expired_date: item.expiration_date,
          created_at: item.created_at,
        }));
        this.totalItems = response.metadata.pagination.totalCount;
        this.updatePaginationIndexes();
      }
    });
  }

  loadPage(page: number) {
    this.page = page;
    this.submitFilter();
  }

  updatePaginationIndexes() {
    this.startIndex = (this.page - 1) * this.pageSize;
    this.endIndex = Math.min(this.startIndex + this.pageSize, this.totalItems);
  }

  onPageSizeChange() {
    this.page = 1;
    this.submitFilter();
  }

  submitFilter() {
    this.searching = true;
    const url = `${environment.host}baas-noti`;

    let params = new HttpParams()
      .set("page", this.page.toString())
      .set("limit", this.pageSize.toString());

    if (this.filter.terminalID) {
      params = params.set("terminalID", this.filter.terminalID);
    }
    if (this.filter.serial_number) {
      params = params.set("serial_number", this.filter.serial_number);
    }
    if (this.filter.service_package) {
      params = params.set("service_package", this.filter.service_package);
    }
    if (this.filter.model) {
      params = params.set("model", this.filter.model);
    }
    if (this.filter.startDate && this.filter.endDate) {
      params = params
        .set("startDate", this.filter.startDate)
        .set("endDate", this.filter.endDate);
    }
    if (this.filter.startActiveDate && this.filter.endActiveDate) {
      params = params
        .set("startActiveDate", this.filter.startActiveDate)
        .set("endActiveDate", this.filter.endActiveDate);
    }

    this.http.get<any>(url, { params }).subscribe((response) => {
      if (response.status === 200) {
        this.listData = response.metadata.results.map((item: any) => ({
          terminalID: item.terminalID,
          serial_number: item.serial_number,
          qrcodeID: item.qrcodeId,
          owner: item.owner,
          model: item.model,
          service_package: item.service_package,
          actived_date: item.activation_date,
          expired_date: item.expiration_date,
          created_at: item.created_at,
        }));
        this.totalItems = response.metadata.pagination.totalCount;
        this.updatePaginationIndexes();
      }
      this.searching = false;
    });
  }

  clearFilter() {
    this.filter = {
      terminalID: "",
      serial_number: "",
      service_package: "",
      model: "",
      startDate: null,
      endDate: null,
      startActiveDate: null,
      endActiveDate: null,
    };
    this.clearDate();
    this.clearActivationDate();
    this.page = 1;
    this.submitFilter();
  }

  editItem(item: BaasDevice | null = null) {
    this.isNewItem = !item;
    this.editedItem = item ? { ...item } : this.getEmptyDevice();
    this.modalService.open(this.editModal, { size: "lg" });
  }

  deleteItem(item: BaasDevice) {
    this.selectedItem = item;
    this.modalService.open(this.deleteModal);
  }

  saveItem() {
    const url = `${environment.host}baas-noti`;

    // Tạo bản sao của dữ liệu để xử lý
    const deviceData = {
      ...this.editedItem,
      activation_date: this.editedItem.actived_date, // Map lại tên field cho đúng với API
      expiration_date: this.calculateExpirationDate(
        this.editedItem.actived_date,
        this.editedItem.service_package
      ),
    };

    if (this.isNewItem) {
      this.http.post(url, deviceData).subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.modalService.dismissAll();
            this.loadData();
          }
        },
        (error) => {
          console.error("Lỗi khi thêm thiết bị:", error);
        }
      );
    } else {
      // Gửi PUT request để cập nhật
      this.http
        .put(`${url}/${this.editedItem.terminalID}`, this.editedItem)
        .subscribe(
          (response: any) => {
            if (response.status === 200) {
              this.modalService.dismissAll();
              this.loadData();
            }
          },
          (error) => {
            console.error("Lỗi khi cập nhật thiết bị:", error);
            // Xử lý lỗi ở đây
          }
        );
    }
  }

  confirmDelete() {
    if (this.selectedItem) {
      const index = this.listData.findIndex(
        (item) => item.terminalID === this.selectedItem?.terminalID
      );
      if (index !== -1) {
        this.listData.splice(index, 1);
      }
      this.modalService.dismissAll();
      this.loadData();
    }
  }

  private getEmptyDevice(): BaasDevice {
    return {
      terminalID: "",
      serial_number: "",
      qrcodeID: "",
      owner: "",
      model: "4G",
      service_package: 3,
      actived_date: "",
      expired_date: "",
      created_at: "",
    };
  }

  // Thêm hàm tính ngày hết hạn
  private calculateExpirationDate(
    activationDate: string,
    months: number
  ): string {
    const date = new Date(activationDate);
    date.setMonth(date.getMonth() + months);
    return date.toISOString().split("T")[0]; // Format YYYY-MM-DD
  }

  // Thêm các phương thức mới
  importExcel() {
    this.selectedFile = null;
    this.modalService.open(this.importModal);
  }

  onFileSelect(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  submitImport() {
    if (!this.selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", this.selectedFile);

    this.http
      .post(`${environment.host}baas-noti/import-excel`, formData)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.modalService.dismissAll();
            this.loadData();
            // Có thể thêm thông báo thành công ở đây
          }
        },
        (error) => {
          console.error("Lỗi khi import file:", error);
          // Xử lý lỗi và hiển thị thông báo lỗi
        }
      );
  }

  // Thêm methods mới
  onActivationDateSelection(date: NgbDate) {
    if (!this.fromActivationDate && !this.toActivationDate) {
      this.fromActivationDate = date;
    } else if (
      this.fromActivationDate &&
      !this.toActivationDate &&
      date &&
      date.after(this.fromActivationDate)
    ) {
      this.toActivationDate = date;
      this.selectedActivation = this.formatActivationDateRange();
      this.filter.startActiveDate = this.formatDate(this.fromActivationDate);
      this.filter.endActiveDate = this.formatDate(this.toActivationDate);
      this.hiddenActivation = true;
    } else {
      this.toActivationDate = null;
      this.fromActivationDate = date;
    }
  }

  isHoveredActivation(date: NgbDate) {
    return (
      this.fromActivationDate &&
      !this.toActivationDate &&
      this.hoveredActivationDate &&
      date.after(this.fromActivationDate) &&
      date.before(this.hoveredActivationDate)
    );
  }

  isInsideActivation(date: NgbDate) {
    return (
      this.toActivationDate &&
      date.after(this.fromActivationDate) &&
      date.before(this.toActivationDate)
    );
  }

  isRangeActivation(date: NgbDate) {
    return (
      date.equals(this.fromActivationDate) ||
      (this.toActivationDate && date.equals(this.toActivationDate)) ||
      this.isInsideActivation(date) ||
      this.isHoveredActivation(date)
    );
  }

  clearActivationDate() {
    this.selectedActivation = "";
    this.fromActivationDate = null;
    this.toActivationDate = null;
    this.filter.startActiveDate = null;
    this.filter.endActiveDate = null;
  }

  formatActivationDateRange(): string {
    if (this.fromActivationDate && this.toActivationDate) {
      const from = this.formatDate(this.fromActivationDate);
      const to = this.formatDate(this.toActivationDate);
      return `${from} - ${to}`;
    }
    return "";
  }
}
