<div class="container-fluid">
  <app-pagetitle title="{{ 'Thống kê' | translate }}"></app-pagetitle>
  <div class="card px-4 pt-4">
    <div class="row">
      <div class="col-3 mr-2">
        <div class="form-group">
          <label for="validationCustom01">{{
            "Chọn ngày: " | translate
          }}</label>
          <div>
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
            <div class="position-relative">
              <input
                type="text"
                class="form-control date"
                (click)="hidden = !hidden"
                name="daterange"
                autocomplete="off"
                [(ngModel)]="selected"
                readonly
                placeholder="{{ 'Ngày/ Tháng/ Năm' | translate }}"
              />
              <span class="clearDate" *ngIf="selected" (click)="clearDate()"
                >x</span
              >
            </div>

            <ngb-datepicker
              #dp
              (select)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [hidden]="hidden"
            >
            </ngb-datepicker>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div style="min-width: 200px; padding-left: 15px">
          <div class="form-group">
            <label for="validationCustom01" class="d-block">&nbsp;</label>
            <button class="btn btn-primary" (click)="submitFilter()">
              {{ "PAGE.REPORT.Search" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <!-- Card 1 -->
          <div class="col-4">
            <div class="card" (click)="toTrans()">
              <div
                class="card-body d-flex flex-column align-items-center justify-content-center text-center"
              >
                <h5 class="font-weight-bolder">Doanh thu</h5>
                <div *ngIf="dataLoading" class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!dataLoading" class="font-weight-bold text-success">
                  {{ dataRevenue?.total_amount | number : "1.0-0" }} VNĐ
                </div>
              </div>
            </div>
          </div>
          <!-- Card 2 -->
          <div class="col-4">
            <div class="card" (click)="toTrans()">
              <div
                class="card-body d-flex flex-column align-items-center justify-content-center text-center"
              >
                <h5 class="font-weight-bolder">Giao dịch</h5>
                <div *ngIf="dataLoading" class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!dataLoading" class="text-muted">
                  {{ dataRevenue?.total_transactions }} giao dịch
                </div>
              </div>
            </div>
          </div>
          <!-- Card 3 -->
          <div class="col-4">
            <div class="card" (click)="toTrans()">
              <div
                class="card-body d-flex flex-column align-items-center justify-content-center text-center"
              >
                <h5 class="font-weight-bolder">Trung bình</h5>
                <div *ngIf="dataLoading" class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!dataLoading" class="text-muted">{{ AVG | number : "1.0-0" }} VNĐ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 card">
        <div class="text-center mt-3">
          <h5 class="font-weight-bold">Biểu đồ doanh thu</h5>
        </div>
        <div *ngIf="!loadingChart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [stroke]="chartOptions.stroke"
            [fill]="chartOptions.fill"
            [labels]="chartOptions.labels"
            [markers]="chartOptions.markers"
            [yaxis]="chartOptions.yaxis"
            [tooltip]="chartOptions.tooltip"
          >
          </apx-chart>
        </div>
      </div> -->
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-6">
      <div class="mt-3">
        <div class="row">
          <h6 class="col-9">GIAO DỊCH LỚN NHẤT</h6>
        </div>
      </div>
      <div class="card max-h-600">
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
          >
            <thead>
              <tr class="bg-table">
                <th>#</th>
                <th class="min-width-200">{{ "Cửa hàng" | translate }}</th>
                <th>{{ "Mã giao dịch" | translate }}</th>
                <th>{{ "Tài khoản ngân hàng" | translate }}</th>
                <th class="min-width-200">{{ "Chủ tài khoản" | translate }}</th>
                <th>{{ "Số tiền" | translate }}</th>
                <th>{{ "Thời gian giao dịch" | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of listData5Largest; let i = index">
              <td>{{ i + (pageSize * pageIndex - pageSize + 1) }}</td>
              <td>
                {{ data?.workspace_name }}
              </td>
              <td>
                {{ data?.code }}
              </td>
              <td>
                {{ data?.terminal_bank_acc }}
              </td>
              <td>
                {{ data?.terminal_bank_acc_name }}
              </td>

              <td>
                {{ data?.total | currency : "VND" : "symbol" : "1.0-0" }}
              </td>

              <td>{{ data.ts_create_at | date : "dd/MM/yyyy HH:mm" }}</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mt-3">
        <div class="row">
          <h6 class="col-9">GIAO DỊCH NHỎ NHẤT</h6>
        </div>
      </div>
      <div class="card max-h-600">
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
          >
            <thead>
              <tr class="bg-table">
                <th>#</th>
                <th class="min-width-200">{{ "Cửa hàng" | translate }}</th>
                <th>{{ "Mã giao dịch" | translate }}</th>
                <th>{{ "Tài khoản ngân hàng" | translate }}</th>
                <th class="min-width-200">{{ "Chủ tài khoản" | translate }}</th>
                <th>{{ "Số tiền" | translate }}</th>
                <th>{{ "Thời gian giao dịch" | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of listData5Smallest; let i = index">
              <td>{{ i + (pageSize * pageIndex - pageSize + 1) }}</td>
              <td>
                {{ data?.workspace_name }}
              </td>
              <td>
                {{ data?.code }}
              </td>
              <td>
                {{ data?.terminal_bank_acc }}
              </td>
              <td>
                {{ data?.terminal_bank_acc_name }}
              </td>

              <td>
                {{ data?.total | currency : "VND" : "symbol" : "1.0-0" }}
              </td>

              <td>{{ data.ts_create_at | date : "dd/MM/yyyy HH:mm" }}</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="mt-3">
        <div class="row">
          <h6 class="col-9">GIAO DỊCH GẦN ĐÂY</h6>
        </div>
      </div>
      <div class="card">
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
          >
            <thead>
              <tr class="bg-table">
                <th>#</th>
                <th>{{ "Cửa hàng" | translate }}</th>
                <th>{{ "Mã giao dịch" | translate }}</th>
                <th>{{ "Tài khoản ngân hàng" | translate }}</th>
                <th>{{ "Chủ tài khoản" | translate }}</th>
                <th>{{ "Số tiền" | translate }}</th>
                <th>{{ "Thời gian giao dịch" | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of listData10Trans; let i = index">
              <td>{{ i + (pageSize * pageIndex - pageSize + 1) }}</td>  
              <td>
                {{ data?.workspace_name }}
              </td>
              <td>
                {{ data?.code }}
              </td>
              <td>
                {{ data?.terminal_bank_acc }}
              </td>
              <td>
                {{ data?.terminal_bank_acc_name }}
              </td>

              <td>
                {{ data?.total | currency : "VND" : "symbol" : "1.0-0" }}
              </td>

              <td>{{ data.ts_create_at | date : "dd/MM/yyyy HH:mm" }}</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</div>
