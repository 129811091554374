<div class="card">
  <div class="card-body">
    <h5>{{ "Danh sách thiết bị BaaS" | translate }}</h5>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label for="validationCustom01">{{ "Chọn ngày tạo " | translate }}</label>
          <div>
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
            <div class="position-relative">
              <input
                type="text"
                class="form-control date"
                (click)="hidden = !hidden"
                name="daterange"
                autocomplete="off"
                [(ngModel)]="selected"
                readonly
                placeholder="{{ 'Ngày/ Tháng/ Năm' | translate }}"
              />
              <span class="clearDate" *ngIf="selected" (click)="clearDate()">x</span>
            </div>

            <ngb-datepicker
              #dp
              (select)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [hidden]="hidden"
            >
            </ngb-datepicker>
          </div>
        </div>  
          <div class="form-group">
            <label for="validationCustom01">{{ "Chọn ngày kích hoạt" | translate }}</label>
            <div>
              <ng-template #tActivation let-date let-focused="focused">
                <span
                  class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRangeActivation(date)"
                  [class.faded]="isHoveredActivation(date) || isInsideActivation(date)"
                  (mouseenter)="hoveredActivationDate = date"
                  (mouseleave)="hoveredActivationDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
  
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control date"
                  (click)="hiddenActivation = !hiddenActivation"
                  name="activationDaterange"
                  autocomplete="off"
                  [(ngModel)]="selectedActivation"
                  readonly
                  placeholder="{{ 'Ngày/ Tháng/ Năm' | translate }}"
                />
                <span class="clearDate" *ngIf="selectedActivation" (click)="clearActivationDate()">x</span>
              </div>
  
              <ngb-datepicker
                #dpActivation
                (select)="onActivationDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="tActivation"
                outsideDays="hidden"
                [hidden]="hiddenActivation"
              >
              </ngb-datepicker>
            </div>
          </div>
      </div>  

      <div class="col-3">
        <div class="form-group">
          <label for="validationCustom01">{{ "Terminal ID" | translate }}</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="filter.terminalID"
            placeholder="{{ 'Nhập Terminal ID' | translate }}"
          />
        </div>
        <div class="form-group">
          <label for="validationCustom01">{{ "Gói dịch vụ" | translate }}</label>
          <select class="form-control" [(ngModel)]="filter.service_package" (click)="submitFilter()">
            <option value="">{{ "Tất cả" | translate }}</option>
            <option value="3">3 tháng</option>
            <option value="6">6 tháng</option>
            <option value="12">12 tháng</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label for="validationCustom01">{{ "Mã thiết bị" | translate }}</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="filter.serial_number"
            placeholder="{{ 'Nhập mã thiết bị' | translate }}"
          />
        </div>
        <div class="form-group">
          <label for="validationCustom01">{{ "Loại thiết bị" | translate }}</label>
          <select class="form-control" [(ngModel)]="filter.model" (click)="submitFilter()">
            <option value="">{{ "Tất cả" | translate }}</option>
            <option value="4G">4G</option>
            <option value="WIFI">WIFI</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label for="validationCustom01" class="d-block">&nbsp;</label>
          <button class="btn btn-primary" (click)="submitFilter()">
            {{ "PAGE.REPORT.Search" | translate }}
          </button>
          <button class="btn btn-dark ml-2" (click)="clearFilter()">
            {{ "FORM.Clear" | translate }}
          </button>
        </div>
        <div class="form-group">
          <label class="d-block">&nbsp;</label>
          <button class="btn btn-success" (click)="editItem()">
            {{ "Thêm mới" | translate }}
          </button>
          <button class="btn btn-warning ml-2" (click)="importExcel()">
            <i class="mdi mdi-file-excel"></i> {{ "Import Excel" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="row mb-md-2"></div>

    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table dt-responsive nowrap w-100"
      >
        <thead>
          <tr class="text-white">
            <th>#</th>
            <th>{{ "Terminal ID" | translate }}</th>
            <!-- <th>{{ "QR Code" | translate }}</th> -->
            <th>{{ "Người sở hữu" | translate }}</th>
            <th>{{ "Mã thiết bị" | translate }}</th>
            <th>{{ "Loại thiết bị" | translate }}</th>
            <th>{{ "Gói dịch vụ" | translate }}</th>
            <th>{{ "Ngày kích hoạt" | translate }}</th>
            <th>{{ "Ngày hết hạn" | translate }}</th>
            <th>{{ "Ngày tạo" | translate }}</th>
            <!-- <th>{{ "Thao tác" | translate }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listData; let i = index">
            <td>{{ (page-1)*pageSize + i + 1 }}</td>
            <td>{{ item.terminalID }}</td>
            <!-- <td>{{ item.qrcodeID }}</td> -->
            <td>{{ item.owner }}</td>
            <td>{{ item.serial_number }}</td>
            <td>{{ item.model }}</td>
            <!-- <td>{{item.service_package}}</td> -->
            <td>{{ item.service_package ? item.service_package + ' tháng' : '' }}</td>
            <td>{{ item.actived_date | date:'dd/MM/yyyy' }}</td>
            <td>{{ item.expired_date | date:'dd/MM/yyyy' }}</td>
            <td>{{ item.created_at | date:'dd/MM/yyyy' }}</td>
            <!-- <td>
              <a
                  class="font-size-18 text-success"
                  ngbTooltip="{{ 'Chi tiết' | translate }}"
                >
                  <i class="mdi mdi-eye"></i>
                </a>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row justify-content-md-between align-items-md-center mt-2">
      <div class="col-sm-12 col-md-5">
        <div class="dataTables_info mb-2">
          {{ "Hiển thị" | translate }} {{ startIndex + 1 }} {{ "đến" | translate }}
          {{ endIndex }} {{ "của" | translate }} {{ totalItems }}
          {{ "bản ghi" | translate }}
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-right pagination-rounded">
          <ngb-pagination
            [collectionSize]="totalItems"
            [(page)]="page"
            [pageSize]="pageSize"
            (pageChange)="loadPage($event)"
            [maxSize]="5"
          >
          </ngb-pagination>
        </div>
        <div>
          <label class="d-inline-flex align-items-center mb-0">
            {{ "TABLE.Show" | translate }}
            <select
              name="tickets-table_length"
              aria-controls="tickets-table"
              name="pageSize"
              [(ngModel)]="pageSize"
              (ngModelChange)="onPageSizeChange()"
              class="custom-select custom-select-sm form-control form-control-sm mx-2"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
            {{ "TABLE.entries" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- add Modal -->
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ isNewItem ? 'Thêm mới thiết bị' : 'Chỉnh sửa thiết bị' }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editForm="ngForm">
      <div class="form-group">
        <label>Terminal ID</label>
        <input type="text" class="form-control" [(ngModel)]="editedItem.terminalID" name="terminalID" required>
      </div>
      <div class="form-group">
        <label>Mã thiết bị</label>
        <input type="text" class="form-control" [(ngModel)]="editedItem.serial_number" name="serial_number" required>
      </div>
      <div class="form-group">
        <label>Người sở hữu</label>
        <input type="text" class="form-control" [(ngModel)]="editedItem.owner" name="owner" required>
      </div>
      <div class="form-group">
        <label>Loại thiết bị</label>
        <select class="form-control" [(ngModel)]="editedItem.model" name="model" required>
          <option value="WIFI">WIFI</option>
          <option value="4G">4G</option>
        </select>
      </div>
      <div class="form-group">
        <label>Gói dịch vụ (tháng)</label>
        <select class="form-control" [(ngModel)]="editedItem.service_package" name="service_package" required>
          <option [ngValue]="3">3 tháng</option>
          <option [ngValue]="6">6 tháng</option>
          <option [ngValue]="12">12 tháng</option>
        </select>
      </div>
      <div class="form-group">
        <label>Ngày kích hoạt</label>
        <input type="date" class="form-control" [(ngModel)]="editedItem.actived_date" name="actived_date" required>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Đóng</button>
    <button type="button" class="btn btn-primary" (click)="saveItem()" [disabled]="!editForm.form.valid">
      {{ isNewItem ? 'Thêm mới' : 'Cập nhật' }}
    </button>
  </div>
</ng-template>

<!-- Delete Confirmation Modal -->
<!-- <ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Xác nhận xóa</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Bạn có chắc chắn muốn xóa thiết bị này?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Hủy</button>
    <button type="button" class="btn btn-danger" (click)="confirmDelete()">Xóa</button>
  </div>
</ng-template> -->

<!-- Import Excel Modal -->
<ng-template #importModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ "Import từ Excel" | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ "Chọn file Excel" | translate }}</label>
      <input type="file" class="form-control" (change)="onFileSelect($event)" accept=".xlsx, .xls">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ "Đóng" | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="submitImport()" [disabled]="!selectedFile">
      {{ "Import" | translate }}
    </button>
  </div>
</ng-template>

